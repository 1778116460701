export const ACTIVITY_TYPES = {
    RIDE: {
        id: 'Ride',
        color: 'rgba(255, 75, 75, 1)',
        backgroundColor: 'rgba(255, 75, 75, 0.5)',
        displayName: 'Rides'
    },
    HIKE: {
        id: 'Hike',
        color: 'rgba(75, 123, 255, 1)',
        backgroundColor: 'rgba(75, 123, 255, 0.5)',
        displayName: 'Hikes'
    },
    RUN: {
        id: 'Run',
        color: 'rgba(75, 207, 109, 1)', 
        backgroundColor: 'rgba(75, 207, 109, 0.5)',
        displayName: 'Runs'
    }
};