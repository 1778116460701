window._ = require('lodash');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import './echo';
import Alpine from "alpinejs";
import { initializeChart } from './chart';
import { initializeMapbox, initializeTotalMapbox, initializeMapFilter, initializeActivityDropdown } from './mapbox';

window.Alpine = Alpine;
Alpine.start();

// Initialize all components
document.addEventListener('DOMContentLoaded', () => {
  initializeChart();
  initializeMapbox();
  initializeTotalMapbox();
  initializeMapFilter();
  initializeActivityDropdown();
});