import mapboxgl from "mapbox-gl";
import polyline from "@mapbox/polyline";
import { ACTIVITY_TYPES } from './config';

const MAP_CONFIG = {
    style: "mapbox://styles/septemberdigital/cl4d2blyc000114qmqjiqeruf",
    defaultZoom: 11,
    defaultCenter: [5.1214, 52.0907],
    fitBoundsPadding: 80
};

const LAYER_CONFIG = {
    glow: {
        baseWidth: 5,
        zoomedWidth: 8,
        baseBlur: 6,
        zoomedBlur: 5,
        opacity: 0.5,
    },
    secondaryGlow: {
        baseWidth: 2,
        zoomedWidth: 6,
        baseBlur: 1,
        zoomedBlur: 3,
        opacity: 0.25,
    },
    route: {
        baseWidth: 2,
        zoomedWidth: 3,
        opacity: 0.7,
    },
    zoomLevels: {
        min: 10,
        max: 15
    }
};

const createGeoJSON = (coordinates) => ({
    type: "Feature",
    geometry: {
        type: "LineString",
        coordinates,
    },
});

const decodePolyline = (encodedPolyline) =>
    polyline.decode(encodedPolyline).map(([lat, lng]) => [lng, lat]);

const createMapInstance = (containerId, options = {}) => {
    const defaultOptions = {
        style: MAP_CONFIG.style,
        zoom: MAP_CONFIG.defaultZoom,
        accessToken: mapbox_access_token,
    };

    return new mapboxgl.Map({
        container: containerId,
        ...defaultOptions,
        ...options
    });
};

const createGlowLayer = (activityType, prefix, config) => ({
    id: `${prefix}-${activityType.id.toLowerCase()}`,
    type: "line",
    source: "routes",
    paint: {
        "line-color": activityType.color,
        "line-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            LAYER_CONFIG.zoomLevels.min, config.baseWidth,
            LAYER_CONFIG.zoomLevels.max, config.zoomedWidth
        ],
        "line-opacity": config.opacity,
        ...(config.baseBlur && {
            "line-blur": [
                "interpolate",
                ["linear"],
                ["zoom"],
                LAYER_CONFIG.zoomLevels.min, config.baseBlur,
                LAYER_CONFIG.zoomLevels.max, config.zoomedBlur
            ]
        })
    },
    filter: ["==", "type", activityType.id],
});

const addActivityLayers = (map, activityType) => {
    const layers = [
        createGlowLayer(activityType, 'glow', LAYER_CONFIG.glow),
        createGlowLayer(activityType, 'glow2', LAYER_CONFIG.secondaryGlow),
        createGlowLayer(activityType, 'route', LAYER_CONFIG.route)
    ];

    layers.forEach(layer => map.addLayer(layer));
    setupLayerInteractivity(map, activityType);
};

const handleRouteClick = (e) => {
    const route = e.features[0];
    new mapboxgl.Popup()
        .setHTML(`
        <div class="strawalky-popup">
          <h2>${route.properties.title} <span>${route.properties.start_date}</span></h2>
          <p>${route.properties.distance}km</p>
        </div>
      `)
        .setLngLat(e.lngLat.wrap())
        .addTo(e.target);
};

const setupLayerInteractivity = (map, activityType) => {
    const layerId = `route-${activityType.id.toLowerCase()}`;

    // Cursor interactions
    map.on("mouseenter", layerId, () => {
        map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", layerId, () => {
        map.getCanvas().style.cursor = "grab";
    });
    
    // Click handler
    map.on("click", layerId, handleRouteClick);
};

const setupToggleListeners = (map) => {
    Object.values(ACTIVITY_TYPES).forEach(type => {
        const toggleElement = document.getElementById(`toggle${type.id}`);
        if (!toggleElement) return;

        toggleElement.addEventListener('change', (e) => {
            const visibility = e.target.checked ? 'visible' : 'none';
            const baseId = type.id.toLowerCase();
            
            ['route', 'glow', 'glow2'].forEach(prefix => {
                map.setLayoutProperty(
                    `${prefix}-${baseId}`,
                    'visibility',
                    visibility
                );
            });
        });
    });
};

const createActivityFeature = (activity) => {
    if (!activity?.polyline) return null;

    const coordinates = decodePolyline(activity.polyline);
    const activityType = ACTIVITY_TYPES[activity.type.toUpperCase()];

    if (!activityType) {
        console.debug(`No activity type mapping for: ${activity.type}`);
        return null;
    }

    return {
        type: "Feature",
        properties: {
            title: activity.name || activity.title,
            distance: activity.distance,
            moving_time: activity.moving_time,
            start_date: activity.start_date,
            type: activityType.id,
            color: activityType.color,
        },
        geometry: {
            type: "LineString",
            coordinates,
        },
    };
};

export async function initializeMapbox() {
    const mapboxElement = document.getElementById("mapbox");
    if (!mapboxElement) return;

    const coordinates = decodePolyline(mapboxElement.dataset.polyline);
    const map = createMapInstance("mapbox", { 
        center: coordinates[0],
        zoom: 9 
    });

    map.on("load", () => {
        // Add route to map
        map.addSource("route", { 
            type: "geojson", 
            data: createGeoJSON(coordinates) 
        });
        
        // Add single route layer
        map.addLayer({
            id: "route",
            type: "line",
            source: "route",
            paint: {
                "line-color": "rgb(129, 140, 248)",
                "line-opacity": 0.75,
                "line-width": 5,
            },
        });

        // Fit map to route bounds
        const bounds = coordinates.reduce(
            (bounds, coord) => bounds.extend(coord),
            new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
        );

        map.fitBounds(bounds, { padding: MAP_CONFIG.fitBoundsPadding });
    });
}

export async function initializeTotalMapbox() {
    const mapboxTotalElement = document.getElementById("mapbox_total");
    if (!mapboxTotalElement) return;

    const map = createMapInstance("mapbox_total", {
        center: MAP_CONFIG.defaultCenter
    });

    map.on("load", async () => {
        try {
            // Fetch and process activities
            const response = await fetch(`${api_url}/map?api`);
            const activities = await response.json();

            const features = activities
                .map(createActivityFeature)
                .filter(Boolean);

            // Add features to map
            map.addSource("routes", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features,
                },
            });

            // Add layers and set up interactivity
            Object.values(ACTIVITY_TYPES).forEach(type => {
                addActivityLayers(map, type);
            });

            setupToggleListeners(map);

        } catch (error) {
            console.error("Error initializing total map:", error);
        }
    });
}

const UI_CONFIG = {
    selectors: {
        filter: {
            toggle: 'filterToggle',
            panel: 'filterPanel'
        },
        details: {
            toggle: 'detailsToggle',
            panel: 'detailsPanel'
        },
        breakpoints: {
            mobile: 768
        }
    }
};

const setupOutsideClickHandler = (panel, toggle, hidePanel) => {
    document.addEventListener('click', (e) => {
        if (!panel.contains(e.target) && !toggle.contains(e.target)) {
            hidePanel();
        }
    });
};

const setupMobileResponsiveness = (panel) => {
    window.addEventListener('resize', () => {
        panel.classList.toggle('hidden', window.innerWidth < UI_CONFIG.selectors.breakpoints.mobile);
    });
};

export async function initializeMapFilter() {
    const toggle = document.getElementById(UI_CONFIG.selectors.filter.toggle);
    const panel = document.getElementById(UI_CONFIG.selectors.filter.panel);

    if (!toggle || !panel) return;

    const hidePanel = () => panel.classList.add('hidden');
    const togglePanel = (e) => {
        e.stopPropagation();
        panel.classList.toggle('hidden');
    };

    toggle.addEventListener('click', togglePanel);
    setupOutsideClickHandler(panel, toggle, hidePanel);
    setupMobileResponsiveness(panel);
}

export async function initializeActivityDropdown() {
    const toggle = document.getElementById(UI_CONFIG.selectors.details.toggle);
    const panel = document.getElementById(UI_CONFIG.selectors.details.panel);

    if (!toggle || !panel) return;

    const hidePanel = () => panel.classList.add('hidden');
    const togglePanel = (e) => {
        e.stopPropagation();
        panel.classList.toggle('hidden');
    };

    toggle.addEventListener('click', togglePanel);
    setupOutsideClickHandler(panel, toggle, hidePanel);
    setupMobileResponsiveness(panel);
}