import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: process.env.MIX_REVERB_APP_KEY,
    wsHost: process.env.MIX_REVERB_HOST,
    wsPort: process.env.MIX_REVERB_PORT ?? 80,
    wssPort: process.env.MIX_REVERB_PORT ?? 443,
    forceTLS: (process.env.MIX_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});

window.Echo.private(`user.${user_id}`)
    .listen('UpdateDashboard', (e) => {
        console.log('UpdateDashboard event received');
        window.location.reload();
    });
