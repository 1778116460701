import { ACTIVITY_TYPES } from "./config";
import Chart from "chart.js/auto";

const DAILY_RANGES = ['this-week', 'last-7-days', 'prev-week', 'this-month', 'last-30-days', 'prev-month'];
const WEEKLY_RANGES = ['this-year', 'prev-year', 'all'];

// Helper function to parse DD-MM-YYYY to Date object
const parseDate = (dateString) => {
  const [day, month, year] = dateString.split('-');
  return new Date(year, month - 1, day);
};

// Helper for EU date formatting
const formatDateEU = (date, format = 'short') => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  
  switch (format) {
    case 'full':
      const weekday = date.toLocaleString('en-GB', { weekday: 'long' });
      return `${day}/${month}/${year}`;
    case 'monthYear':
      const monthName = date.toLocaleString('en-GB', { month: 'long' });
      return `${monthName} ${year}`;
    case 'short':
      return `${day}/${month}`;
    case 'monthShort':
      return date.toLocaleString('en-GB', { month: 'short' });
    default:
      return `${day}/${month}`;
  }
};

const getXAxisFormat = (labels, range) => {
  if (WEEKLY_RANGES.includes(range)) {
    return {
      callback: (value, index) => {
        const date = parseDate(labels[index]);
        return formatDateEU(date, 'monthShort');
      },
      maxTicksLimit: 12
    };
  }
  
  return {
    callback: (value, index) => {
      const date = parseDate(labels[index]);
      return formatDateEU(date, 'short');
    },
    maxTicksLimit: range === 'this-week' || range === 'last-7-days' || range === 'prev-week' ? 7 : 15
  };
};

const chartConfig = (data, activityType, range) => ({
  type: "line",
  data: {
    labels: data.labels,
    datasets: [{
      label: "Distance",
      backgroundColor: activityType.backgroundColor,
      borderColor: activityType.color,
      data: data.distances,
      fill: true,
      tension: 0.1,  
      borderWidth: 4,
      pointRadius: 0,
      pointHoverRadius: 5,
      pointBorderWidth: 3,
      pointBackgroundColor: activityType.color,
      pointBorderColor: 'white',
      pointBorderWidth: 1,
      pointHitRadius: 10,
    }],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            const date = parseDate(context[0].label);
            return formatDateEU(date, 'full');
          },
          label: (context) => {
            const value = context.parsed.y;
            return value === 0 ? 'No activities' : `${value.toFixed(1)} km`;
          },
        },
        intersect: false,
        mode: 'index',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          ...getXAxisFormat(data.labels, range),
          maxRotation: 45,
          minRotation: 45,
        },
        border: {
          display: false,
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
          borderDash: [2, 4],
        },
        border: {
          display: false,
        },
        ticks: {
          callback: (value) => `${value} km`,
          padding: 10,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    animation: {
      duration: 0,
    },
  },
});

export async function initializeChart() {
  const chartElement = document.getElementById("chart");
  const chartDataElement = document.getElementById("activityChart");
  const emptyState = document.getElementById("emptyState");

  if (!chartElement || !chartDataElement) return;

  const dataset = chartDataElement.dataset;
  const filter = new URLSearchParams(window.location.search);
  const range = filter.get('range') || 'last-30-days';
  const activityType = ACTIVITY_TYPES[dataset.type.toUpperCase()] || ACTIVITY_TYPES.RIDE;

  try {
    const response = await fetch(`${dataset.api}&${filter}`);
    const activities = await response.json();

    if (activities.status === "no-results" || !activities.distances.some(d => d > 0)) {
      emptyState?.classList.remove("hidden");
      chartElement?.classList.add("hidden");
      return;
    }

    emptyState?.classList.add("hidden");
    chartElement?.classList.remove("hidden");

    // Destroy existing chart if it exists
    const existingChart = Chart.getChart(chartDataElement);
    if (existingChart) {
      existingChart.destroy();
    }

    new Chart(
      chartDataElement, 
      chartConfig(activities, activityType, range)
    );

  } catch (error) {
    console.error("Error initializing chart:", error);
    emptyState?.classList.remove("hidden");
    chartElement?.classList.add("hidden");
  }
}